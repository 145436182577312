<template>
  <div v-if="!isMobile()" class="event-list-container">
    <div v-for="event in formattedEvents" :key="event.id" class="event-item">
      <v-row>
        <v-col>
          <h4>{{ event.testType }}</h4>
        </v-col>
        <v-col>
          <img v-if="event.eventType=='TEST Attempt'" width="400px" height="400px" :src="event.imageUrl" alt="Event Image" />
          <img v-if="event.eventType=='ENGINE STATUS CHANGE'" width="80px" height="40px" :src="getSource(event)" alt="ENGINE IMAGE" />
        </v-col>
        <v-col>
          <h4>Time:{{ event.localTime }}</h4>
        </v-col>
      </v-row>
      <h4>Location: {{ event.placeName }}</h4>
      
      <h4>Distance travelled: {{ event.distanceTravelled }}</h4>
<v-icon name="location"></v-icon>
    
      <br /><br />
      <v-divider></v-divider>
    </div>
  </div>
  <div v-else class="event-list-container">
    <div v-for="event in formattedEvents" :key="event.id" class="event-item">
      <v-row class="d-flex flex-column align-center text-center event-containers">
  <v-col cols="12">
    <v-row>
    <h4 class="event-titles">{{ event.testType }}</h4>
    <v-spacer></v-spacer>
    <h4 class="event-times">Time: {{ event.localTime }}</h4>
  </v-row>
  </v-col>
  <v-col cols="12">
    
    <div class="image-containers">
      <img 
        v-if="event.eventType == 'TEST Attempt'" 
        class="event-images" 
        :src="event.imageUrl" 
        alt="Event Image" 
      />
      <img 
        v-if="event.eventType == 'ENGINE STATUS CHANGE'" 
        class="engine-images" 
        :src="getSource(event)" 
        alt="ENGINE IMAGE" 
      />
    </div>
    <h4 class="event-times">Location: {{ event.placeName }}</h4>
  </v-col>

</v-row>
      <v-row>
      <h4 class="event-times">Distance travelled: {{ event.distanceTravelled }}</h4>
      <v-spacer></v-spacer>
      <v-icon style="color: black;" @click="openMap(item)">
        mdi-crosshairs-gps</v-icon>
      </v-row>
      <br /><br />
      <v-divider></v-divider>
</div>
  </div>
</template>

<script>
import moment from "moment-timezone";
export default {
  props:["events"],
  data() {
    return {
    //   events: [{"id":7117686,"devid":"ABS_a0_a3_b3_98_6e_2c","controlId":"ABC_fc_b4_67_7b_41_a8","gtime":"","rtime":"09/06/2024 08:29:42","typeofevent":"254","bacvalue":"0","passfailstatus":"1","enginerunstate":"0","randominitial":"0","lat":"17.898","lon":"78.654","vbat":"NA","created_at":"2024-04-27 14:50:18.064143791"},
    // {"id":7117687,"devid":"ABS_a0_a3_b3_98_6e_2c","controlId":"ABC_fc_b4_67_7b_42_3c","gtime":"","rtime":"04/27/2024 14:50:26","typeofevent":"15","bacvalue":"255","passfailstatus":"255","enginerunstate":"1","randominitial":"255","lat":"17.678","lon":"78.900","vbat":"NA","created_at":"2024-04-27 14:50:29.910074474"},
    // {"id":7117688,"devid":"ABS_a0_a3_b3_98_6e_2c","controlId":"ABC_fc_b4_67_7b_42_3c","gtime":"","rtime":"04/27/2024 14:50:26","typeofevent":"254","bacvalue":"255","passfailstatus":"1","enginerunstate":"255","randominitial":"1","lat":"18.878","lon":"78.654","vbat":"NA","created_at":"2024-04-27 14:50:30.918800635"},
    // {"id":7123499,"devid":"ABS_a0_a3_b3_98_6e_2c","controlId":"ABC_fc_b4_67_7b_42_3c","gtime":"","rtime":"04/27/2024 14:51:22","typeofevent":"15","bacvalue":"255","passfailstatus":"255","enginerunstate":"0","randominitial":"255","lat":"18.999","lon":"79.766","vbat":"NA","created_at":"2024-05-01 07:36:53.182903505"}],
      placeNames: {}, // Store place names here
    };
  },
  mounted(){
    // console.log("hhhhhhhh")
    // console.log(this.events);
  },  
  computed: {
    formattedEvents() {
      return this.events.map((event, index, array) => {
        // console.log(event)
        const eventType =
          event.typeofevent === "254"
            ? "TEST Attempt"
            : event.typeofevent === "15" || event.typeofevent === "4"
            ? "ENGINE STATUS CHANGE"
            : "Unknown";

        const testType =
          event.typeofevent === "254" && event.randominitial === "0"
            ? "INITIAL TEST"
            : event.typeofevent === "254" && event.randominitial === "1"
            ? "RANDOM TEST"
            : event.typeofevent =="4"?"BYPASS":event.typeofevent === "15"
            && event.enginerunstate === "1"
              ? "ENGINE ON"
              :event.typeofevent === "15"
              && event.enginerunstate =="0"? "ENGINE OFF"
            :event.typeofevent =="65"?"INSUFFICIENT VOLUME": "N/A";
 // Convert rtime to Asia/Kolkata local time using moment-timezone
      const localTime = moment.tz(event.rtime, "GMT").tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
        let distanceTravelled = "N/A";
        let timeDifference = "N/A";
        let placeName = this.placeNames[event.id] || "Fetching...";

        // Fetch place name if not already fetched
        if (!this.placeNames[event.id]) {
          this.fetchPlaceName(event.lat, event.lon, event.id);
        }

        if (index > 0) {
          const prevEvent = array[index - 1];
          // Distance calculation
          distanceTravelled = this.calculateDistance(
            parseFloat(prevEvent.lat),
            parseFloat(prevEvent.lon),
            parseFloat(event.lat),
            parseFloat(event.lon)
          );
          // Time difference calculation
          const prevTime = new Date(prevEvent.rtime);
          const currTime = new Date(event.rtime);
          const diffInMs = currTime - prevTime;
          timeDifference = (diffInMs / (1000 * 60)).toFixed(2); // Convert to minutes
        }
        let imageUrl = null;
        if (event.typeofevent === "254") {
          imageUrl = `https://robopower.xyz/app/getImage/getb64v44?deviceId=${event.controlId}&Image=${event.rtime}`;
        }
        return {
          ...event,
          eventType,
          testType,
          distanceTravelled,
          timeDifference,
          placeName, // Set place name here
          imageUrl,
          localTime 
        };
      });
    },
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.mobileview = true;
        return true
      } else {
        this.mobileview = false;
        return false
      }
    },
    getSource(event){
      if(event.testType =="ENGINE ON"){
        
        return require("../assets/engineon.jpg");
      } else if(event.testType =="ENGINE OFF"){
        return require("../assets/engineoff.png");
      } else{
        return require("../assets/distanceicon.png");
      }
      
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
  const toRad = (value) => (value * Math.PI) / 180;
  const R = 6371; // Radius of the Earth in km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceInKm = R * c; // Distance in km
if(!isNaN(distanceInKm)){
  if (distanceInKm < 1) {
    return `${(distanceInKm * 1000).toFixed(2)} meters`; // Convert to meters if below 1 km
  } else {
    return `${distanceInKm.toFixed(2)} km`; // Return in kilometers if above 1 km
  }
} else{
  return '0 km'
}
},

    async fetchPlaceName(lat, lon, eventId) {
      const accessToken =
        "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";
      try {
        if(lat != "NA"){
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${accessToken}`
        );
        const data = await response.json();
        const placeName = data.features[0]?.place_name || "Unknown Location";

        // Update the placeNames data object reactively
        this.$set(this.placeNames, eventId, placeName);
        } else{
          const placeName = "Unknown Location";

        // Update the placeNames data object reactively
        this.$set(this.placeNames, eventId, placeName);
        }
      } catch (error) {
        console.error("Error fetching place name:", error);
        this.$set(this.placeNames, eventId, "Unknown Location");
      }
    },
  },
};
</script>
<style scoped>
.event-list-container {
  max-height: 1000px;
  overflow-y: auto;
  padding: 10px;
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari, etc.) */
.event-list-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Hide scrollbar for Firefox */
.event-list-container {
  scrollbar-width: none; /* For Firefox */
}

/* Hide scrollbar for Internet Explorer and Edge */
.event-list-container {
  -ms-overflow-style: none; /* IE and Edge */
}

.event-item {
  margin-bottom: 20px; /* Add some space between items */
}
/* Mobile View CSS */
.event-containers {
  padding: 10px;
}

.event-titles {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.image-containers {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.event-images {
  width: 100%;
  max-width: 300px;
  height: 300px;
  object-fit: cover;
}

.engine-images {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.event-times {
  font-size: 1.2rem;
}

@media (max-width: 600px) {
  .event-titles {
    font-size: 1rem;
  }

  .event-images {
    max-width: 300px;
    height: 200px;
  }

  .engine-images {
    width: 60px;
    height: 60px;
  }

  .event-times {
    font-size: 1rem;
  }
}
</style>
